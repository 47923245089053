.my-react-player {
    min-width: 500px;
    max-width: 500px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(20, 22, 43, .7);
    font-family: var(--body-font);
    font-size: 12px;
    font-weight: 300;
    padding: 0 20px 0 20px;
    box-shadow: 0 0 0 2px rgb(134 140 160 / 2%);
    color: var(--theme-color);
    position: relative;

    @media only screen and (max-width: 569px) {
        min-width: 100%;
        max-width: 100%;
    }

}

.player-wrap-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;  
    position: absolute;
    right: 15px;
    background-color: rgba(20, 22, 43, .0);
    
    @media only screen and (max-width: 350px) {
        position: relative;
        right: 0px;
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 310px) {
        margin-top: 10px;
        flex-direction: column;
    }
}

.player {
    display: none;
}

.player-controls-wrap {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;

    & p {
        @media only screen and (max-width: 1035px) {
            color: white !important;
            font-size: 10px !important;
            line-height: 1px;
        }
    }
}

.player-control-wrap {
    cursor: pointer;
    color: white;
    background-color: var(--theme-button-color);
    border-radius: 180px;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-weight: 500;

    &-yt {
        font-size: 9px;
        text-decoration: none;
    }

    &-pause-sign {
        transform: rotate(90deg) translateY(1px);
    }

    &-play-sign {
        font-size: 9px;
    }
}

/* range-slider */
.slider-wrap {
    min-width: 95px;
    max-width: 95px;
    height: 100%;
}

.slider {
    padding: 0 !important;
    margin-top: 5px;

    @media only screen and (max-width: 350px) {
        margin-top: 10px;
    }
}

.slider::-webkit-slider-thumb {
    cursor: pointer;
}

.slider::-moz-range-thumb {
    cursor: pointer;
}

/* text carousel */

.text-carousel {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.6);
}

.text-carousel span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
    font-size: 12px;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

.song-number {
    margin-right: 10px;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px !important;

    @media only screen and (max-width: 650px) {
        font-size: 12px !important;
    }

    @media only screen and (max-width: 515px) {
        font-size: 11px !important;
    }
}

.player-text-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 15px;
    width: 50%;

    @media only screen and (max-width: 550px) {
        width: 40%;
    }

    @media only screen and (max-width: 450px) {
        width: 30%;
    }

    @media only screen and (max-width: 350px) {
        position: relative;
        left: 0;
        width: 100%;
    }

    @media only screen and (max-width: 350px) {
        margin-top: 15px;
    }
}


.radio-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
      

    @media only screen and (max-width: 350px) {
        flex-direction: column;
        width: 100%;
    }
}

.add-video-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 350px) {
        flex-direction: column;
        align-items: center;
    }

}

.add-video-buttons-wrap {
    position: absolute;
    right: 0;

    @media only screen and (max-width: 350px) {
        position: relative;
        margin-bottom: 15px;
    }
}

.add-video-btn {
    background-color: var(--theme-button-color);
    color: white;
    border: none;
    padding: 2px 16px;
    border-radius: 20px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;

    &-alt {
        background-color: var(--inactive-color);
        margin-left: 10px;
    }
}