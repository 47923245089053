.pop-up {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: (translate(-50%, -50%));
    overflow-y: auto;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    transition: all 0.3s;
    z-index: 11;
    background-color: var(--popup-bg);
    width: 65%;
    height: 90%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    white-space: normal;
    color: white;

    @media screen and (max-width: 1000px) {
        width: 70%;
        height: 80%;
    }

    @media screen and (max-width: 940px) {
        width: 80%;
        height: 80%;
    }

    @media screen and (max-width: 850px) {
        width: 85%;
        height: 80%;
    }

    @media screen and (max-width: 520px) {
        width: 100%;
        height: 100%;
    }

    &__title {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--border-color);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__content {
            flex: 1;
        }
    }

    &__subtitle {
        white-space: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.8em;

        a {
            color: var(--theme-color);
        }
    }
}

.content-button-wrapper .content-button.status-button.open.close {
    width: auto;

}

.content-section .close {
    margin-right: 0;
    width: 24px;
}

.close {
    cursor: pointer;

    @media only screen and (max-width: 450px) {
        margin-bottom: 10px;
    }
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;

    &+.checkbox-wrapper {
        margin: 20px 0 40px;
    }
}

.checkbox {
    display: none;
}

.checkbox+label {
    display: flex;
    align-items: center;

    &:before {
        content: "";
        margin-right: 10px;
        width: 15px;
        height: 15px;
        border: 1px solid var(--theme-color);
        border-radius: 4px;
        cursor: pointer;
        flex-shrink: 0;
    }
}

.checkbox:checked+label:before {
    background-color: #3a6df0;
    border-color: #3a6df0;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
    background-position: 50%;
    background-size: 12px;
    background-repeat: no-repeat;
}

.content-button-wrapper {
    margin-top: auto;
    margin-left: auto;

    .open {
        margin-right: 8px;
    }
}

.overlay-app {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: all;
    background-color: rgba(36, 39, 59, 0.8);
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    z-index: 1;
}

.modal-content-wrapper {
    margin-top: 0px;
    height: 80%;
    overflow: auto;
}

.mm-input {
    padding: 10px 0;
    font-size: 16px;
    color: var(--theme-color);
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    flex: 1;

    & option {

        background: var(--search-bg);
        border-radius: 0;
        color: #fff;
    }
}

.pop-up-content {
    padding: 30px 40px;
    overflow: hidden;
    height: 100%;

    @media only screen and (max-width: 520px) {
        padding: 15px !important;
    }


}

.pmqueue-single-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    & p {
        margin-left: 10px;
    }

    &__span {
        color: var(--inactive-color)
    }
}


.welcome-msg {
    padding-right: 20px;

    & h3 {
        margin-bottom: 0px;
    }

    & p {
        margin-bottom: 10px;
    }

    & a {
        color: var(--theme-color-new);

    }

    & li::before {
        content: '·';
        margin-right: 5px;
    }
}

.admin-banners-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
   
}

.admin-banner-wrap {
    position: relative;
    width: 23%;
    margin-bottom: 10px;
}

.admin-banner {
    width: 100%;
}

.admin-bg {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.questbox-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.questbox-item {
    position: relative;
    margin-right: 20px;
}

.form-wrap {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;

    @media only screen and (max-width: 750px) {
        //flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: -20px;
    } 

    @media only screen and (max-width: 520px) {
       max-width: 95%;
    }
}

.form-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-right: 10px;

    @media only screen and (max-width: 750px) {
        //margin-bottom: 10px;
        min-width: 48%;
    }
}

.form-btn {
    @media only screen and (max-width: 750px) {
        width: 100%;
        margin-top: 30px;
    }
}

.form-close-ico-wrap {
    position: absolute;
    top: 10px;
    right: 10px;
}

.left-col {
    width: 60%;
    padding-right: 50px;

    @media only screen and (max-width: 750px) {
        width: 100%;
    }
}

.right-col {
    width: 40%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    & p {
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
    }

    @media only screen and (max-width: 750px) {
        width: 100%;
    }
}

.my-img {
    min-width: 70px;
    min-height: 70px;
    margin-right: 10px;
    object-fit: cover;
    filter: saturate(4);
    border-color: var(--theme-button-color);
}

.my-text {
    color: var(--theme-button-color);

    &:hover {
        color: var(--theme-color-new);
    }
}

.my-friends {
    font-size: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.donators {
    border-top: 1px solid white;
    padding-top: 10px;
    padding-left: 15px;
}

.donators-box {
    margin-top: -20px;
    background-color: var(--theme-color-new);
    margin-right: 10px;
    padding: 20px;
    border-radius: 0 20px 20px 20px;

    @media only screen and (max-width: 750px) {
        margin-top: 30px;
    }
}

.donations-main-wrap {
    @media only screen and (max-width: 750px) {
        flex-direction: column;
    }
}

.tech-logo {
    width: 100px;
    margin-right: 5px;
    border-radius: 5px;
}

.welcome-msg-title {
    margin-bottom: 10px !important;
    margin-top: 30px !important;
}

.paypal-button-text {
    color: white !important;
}

.controls-titlebar {
    margin-top: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

   /*  @media only screen and (max-width: 750px) {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 10px;
    } */

    &-col1 {
        width: 60%;

        @media only screen and (max-width: 410px) {
            width: 50%;
          }
/* 
        @media only screen and (max-width: 750px) {
            width: 70%;
        }
        @media only screen and (max-width: 300px) {
            width: 90%;
        } */
    }

    &_col2 {
        width: 40%;

        @media only screen and (max-width: 520px) {
           margin-right: 35px;
        }

       
/* 
        @media only screen and (max-width: 300px) {
            width: 90%;
        } */
    }
}

.pagina {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 410px) {
        flex-direction: column;
      }
}

.donation-input {
    width: 10%!important;
}

.profile-image {
    border-radius: 0 16px 16px 16px;
    width: 400px;

    @media only screen and (max-width: 400px) {
        min-width: 100%!important;
    }
}

.profile-content {
    padding: 0px 0 0 40px;
    width: 66%;

    @media only screen and (max-width: 300px) {
        width: 100%;
        padding: 0;
    }
}

.single-user-profile-content {
    padding: 0px 0 0 40px;
    width: 66%;

    @media only screen and (max-width: 400px) {
        min-width: 100%;
        padding: 0;
        margin-top: 20px;
    }
}

.shout-header {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.shout-image-wrap {
    padding: 20px 0 0 30px;
    width: 40%;
    background: rgba(0, 0, 0, 0.3);

    @media only screen and (max-width: 768px) {
        width: 50%;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 10px;
    }
}

.shout-content {
    background-color: var(--scrollbar-bg);
    border-radius: 0px 10px 0 0;
    background: rgba(0, 0, 0, 0.3);
    width: fit-content;
    padding: 20px 30px;
    font-size: 15px;
    word-break: break-word;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    width: 60%;

    @media only screen and (max-width: 768px) {
        width: 50%;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

    &-alt {
        border-radius: 0px 0 10px 10px;
        width: 100%;
    }

    
}

.shout-image {
    object-fit: cover;
    border-radius: 0 10px 10px 10px;

    @media only screen and (max-width: 600px) {
        border-radius: 0 0 10px 10px;
    }
}

.user-gal-item {
    object-fit: cover;
    height: 150px;
    width: 100%;
    border-radius: 0 10px 10px 10px;
}

.user-gal-wrap {
    width: 23%;
    margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
        width: 48%;
    }

    @media only screen and (max-width: 380px) {
        width: 100%;
    }
}

.user-gal-item-visitor {
    object-fit: cover;
    height: 500px;
    width: 100%;
    border-radius: 0 10px 10px 10px;
}

.user-gal-wrap-visitor {
    width: 100%;
    margin-bottom: 10px;
}

.profile-image-wrap {
    width: 33%;

 @media only screen and (max-width: 300px) {
        width: 100%;
    } 
}

.single-user-profile-image-wrap {
    width: 33%;

 @media only screen and (max-width: 400px) {
        width: 100%;
    } 
}

.user-wrap-alt {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   

     @media only screen and (max-width: 300px) {
        flex-direction: column;
        padding-right: 10px;
    } 
}

.profile-header {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 400px) {
        flex-direction: column;
    }
}

//kartice na uvjeti korištenja, features
.app-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
} 

.app-card {
  display: flex;
  flex-direction: column;
  width: 32%;
  font-size: 16px;
  color: white;
  background-color: var(--scrollbar-bg);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  margin-bottom: 20px;

  @media only screen and (max-width: 1200px) {
    width: 49%;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
  }

  cursor: pointer;
  transition: 0.3s ease;

  &-content {
    padding: 0 20px;
  }
  
  img {
    border-radius: 14px 14px 0 0;
    object-fit: cover;
    height: 160px;
    width: 100%;   
  }

  &-img-wrap {
    padding: 10px;
  }

  span {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
   margin-top: 10px;
  }

  &-border {
    border-top: 1px solid var(--border-color);
    margin-bottom: 20px;
  }

  &__subtext {
   font-size: 14px;
   font-weight: 400;
   line-height: 1.6em;
   margin-top: 20px;
   padding-right: 20px;
  }

  &-buttons {
   display: flex;
   align-items: center;
   margin-left: auto;
  }
  
  
 }

 //users
 .user-wrap {
    width: 49%;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
 }

 .online {
    width: 10px;
    height: 10px;
    border-radius: 180px;
    background-color: mediumaquamarine;
 }

 .single-user-wrap {
    border-radius: 0px 10px 10px 10px;
    background: rgba(0, 0, 0, .3);
    width: fit-content;
    padding: 10px 20px;
    font-size: 15px;
    word-break: break-word;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin-top: 20px;
 }

 .scraper-panel {
    display: flex;
    flex-direction: column;
 }

 .scraper-panel-col1 {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
 }

 .scraper-panel-col2 {
    width: 100%;
    display: flex;
    flex-direction: column;
 }