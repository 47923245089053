.main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 30px;
    border-bottom: 1px solid var(--border-color);

    @media only screen and (max-width: 400px) {
        padding: 10px 20px;
    }

    @media only screen and (max-width: 600px) {
        padding: 5px 15px;
    }

    &-alt {
        display: none;

        @media only screen and (max-width: 1200px) {
            display: block;
        }
    }

    .header-menu {
        margin-left: 150px;

        @media screen and (max-width: 1055px) {
            margin: auto;
        }

        a {
            padding: 20px 24px;
        }
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    p {
        color: rgba(255, 255, 255, .6);
        font-size: 14px;
    }
}

.statusbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    @media only screen and (max-width: 1200px) {
        display: none;
    }

    &-alt {
        display: flex;
        justify-content: right;

        @media only screen and (max-width: 385px) {
            justify-content: center;
        }
    }

    & p {
        @media only screen and (max-width: 780px) {
            font-size: 12px;
            text-align: right;
        }

        @media only screen and (max-width: 385px) {
            text-align: center;
        }


    }
}

.botimgttlbr {
    min-width: 19px;
    max-width: 19px;
    min-height: 19px;
    max-height: 19px;
    margin-left: 10px;
    margin-bottom: 1px;
    margin-top: 5px;
    cursor: pointer;
}

.btns-row {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 1200px) {
        width: 100%;
        display: flex;
        align-items: right !important;
        justify-content: right !important;
    }

    @media only screen and (max-width: 385px) {
        justify-content: center !important;
    }
}

.text-carousel-titlebar {
    min-width: 400px;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    color: white;

    @media only screen and (max-width: 1300px) {
        min-width: 300px;
        max-width: 300px;
    }

    @media only screen and (max-width: 1200px) {
        width: 100%;
    }

    @media only screen and (max-width: 780px) {
        min-width: 100%;
        max-width: 100%;
    }

}

.text-carousel-titlebar span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}

.shout-number {
    position: absolute;
    background-color: #3a6df0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: -7px;
    top: -2px;
}

.radijo-btn {
    display: none;

    @media only screen and (max-width: 1100px) {
        display: block;
    }
}

.search-bar-alt {
    width: 100%;

    @media only screen and (max-width: 385px) {
        align-items: center;
    }
}

.shoutbox-ico-wrap {
    margin-right: 2px;
}

.titlebar-ico {
    margin-left: 10px;
    cursor: pointer;

    @media only screen and (max-width: 780px) {
        margin-left: 20px;
    }

    @media only screen and (max-width: 360px) {
        margin-left: 10px;
    }
}