.msg-wrap {
  display: flex;
  flex-direction: row;
}

.message__recipient {
  border-radius: 0px 10px 10px 10px;
  background: rgba(0, 0, 0, .3);
  width: fit-content;
  padding: 10px 20px;
  font-size: 15px;
  word-break: break-word;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  max-width: 600px;

  @media only screen and (max-width: 1450px) {
    max-width: 400px;
  }

  @media only screen and (max-width: 880px) {
    max-width: 320px;
  }

  @media only screen and (max-width: 790px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 700px) {
    max-width: 200px;
  }

  @media only screen and (max-width: 650px) {
    max-width: 100%;
  }
}

.message__sender {
  background: var(--theme-button-color);
  border-radius: 0px 10px 10px 10px;
  width: fit-content;
  padding: 10px;
  font-size: 15px;
  word-break: break-word;
  margin-right: 5px;
  max-width: 600px;

  @media only screen and (max-width: 1450px) {
    max-width: 400px;
  }

  @media only screen and (max-width: 880px) {
    max-width: 320px;
  }

  @media only screen and (max-width: 790px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 700px) {
    max-width: 200px;
  }

  @media only screen and (max-width: 650px) {
    max-width: 100%;
  }


  & a:link,
  a:visited,
  a:hover,
  a:active {
    color: white;
  }

  & p {
    color: white !important;
  }
}

.winner {
  border-radius: 0px 10px 10px 10px;
  width: fit-content;
  padding: 10px;
  font-size: 15px;
  word-break: break-word;
  margin-right: 5px;
  max-width: 600px;

  @media only screen and (max-width: 1450px) {
    max-width: 400px;
  }

  @media only screen and (max-width: 880px) {
    max-width: 320px;
  }

  @media only screen and (max-width: 790px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 700px) {
    max-width: 200px;
  }

  @media only screen and (max-width: 650px) {
    max-width: 100%;
  }


  & a:link,
  a:visited,
  a:hover,
  a:active {
    color: white;
  }

  & p {
    color: white !important;
  }
}

.chat-icons-wrap {
  display: flex;
  flex-direction: row;

  & button {
    background-color: var(--theme-button-color);
    border-radius: 10px;
    border: none;
    width: 50px;
    height: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;

    & img {
      height: 20px;
    }
  }
}

.message__chats {
  margin-bottom: 15px;
}

.message__chats_right {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: right;

}

.message__chats>p {
  font-size: 13px;
}

.sender__name {
  text-align: right;
}

/* .chat-body {
  
  }  */


.creator {
  clip-path: polygon(0% 0%, 4% 14%, 8% 0%, 12% 10%, 16% 0%, 19% 16%, 25% 0%, 29% 14%, 33% 0%, 38% 16%, 43% 0%, 50% 13%, 53% 0%, 57% 16%, 61% 0%, 69% 16%, 72% 0%, 79% 13%, 81% 0%, 87% 16%, 89% 0%, 94% 16%, 100% 5%, 97% 22%, 100% 37%, 97% 45%, 100% 55%, 98% 63%, 100% 70%, 97% 78%, 100% 88%, 97% 93%, 100% 99%, 92% 92%, 89% 100%, 82% 84%, 81% 100%, 69% 85%, 69% 100%, 61% 92%, 59% 100%, 51% 81%, 48% 100%, 40% 86%, 38% 99%, 31% 82%, 28% 100%, 21% 85%, 19% 99%, 8% 94%, 4% 130%, 3% 82%, 0% 94%, 2% 77%, 0% 73%, 1% 63%, 0% 50%, 1% 35%, 0% 25%);

}

.yt-container-wrap {
  width: 60%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.yt-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  border-radius: 20px;
}

/* Then style the iframe to fit in the container div with full height and width */
.yt-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.chat-img {
  border-radius: 20px;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.msg-author {
  margin-bottom: 3px;
}