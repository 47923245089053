.header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 5px 30px;
    white-space: nowrap;

    @media only screen and (max-width: 600px) {
        padding: 5px 15px;
    }

    @media only screen and (max-width: 385px) {
        flex-direction: column;
    }

    &-menu {
        display: flex;

        p {
            padding: 0 15px;
            text-decoration: none;
            color: var(--inactive-color);
            border-bottom: 2px solid transparent;
            transition: 0.3s;
            cursor: pointer;

            @media only screen and (max-width: 490px) {
                padding: 0 10px;
                font-size: 12px;
            }




            &.is-active {
                color: #F9FAFB;

            }

            &:last-child {
                margin-right: 20px;

                @media only screen and (max-width: 500px) {
                    margin-right: 10px;
                }
            }

            &:hover {
                color: var(--theme-color-new);

            }
        }
    }
}

.logo {
    width: 160px;

    @media only screen and (max-width: 520px) {
        display: none;
    }

    @media only screen and (max-width: 385px) {
        display: block;
        width: 100px;
        margin-bottom: 10px;
    }
}

.notify {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        background-color: #3a6df0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        right: 20px;
        top: 16px;
    }

    @media screen and (max-width: 1055px) {
        display: none;
    }
}

.menu-circle {
    margin-right: 60px;
    flex-shrink: 0;
    margin-bottom: -10px;

    @media screen and (max-width: 708px) {
        margin-right: 20px;
    }

    @media screen and (max-width: 400px) {
        margin-right: 10px;
    }
}

.logo-alt {
    display: none;

    @media only screen and (max-width: 520px) {
        display: block;
        width: 70px;
        margin-top: -10px;
    }

    @media only screen and (max-width: 385px) {
        display: none;

    }
}

.search-bar {
    height: 40px;
    min-width: 300px;
    max-width: 300px;
    padding-left: 16px;
    border-radius: 4px;
    margin-left: -10px;

    @media only screen and (max-width: 1100px) {
        display: none;
    }

    &-alt {
        display: none;

        input {
            width: 100%;
            height: 100%;
            border: none;
            font-size: 14px;
            font-weight: 500;
            color: var(--theme-color);

            @media only screen and (max-width: 466px) {
                //margin-bottom: 10px;
        }
            
        }

        @media only screen and (max-width: 1100px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: 40px;
            margin-bottom: 10px;
        }

        @media only screen and (max-width: 350px) {
                height: 65px;
        }

        @media only screen and (max-width: 310px) {
            height: 100px;
        }
    }

    input {
        border: none;
        font-size: 14px;
        color: var(--theme-color);
        
        &::placeholder {
            color: var(--inactive-color);
            font-size: 12px;
        }
    }
}

.header-profile {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: auto;
    flex-shrink: 0;

    @media only screen and (max-width: 385px) {
        width: 100%;
        justify-content: center !important;
        margin-bottom: -5px;
    }

    svg {
        width: 22px;
        color: #f9fafb;
        flex-shrink: 0;
    }
}


.notification {
    position: relative;
    cursor: pointer;
    margin-top: 10px;

    &-number {
        position: absolute;
        background-color: #3a6df0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        right: -7px;
        top: -6px;
    }

    &+svg {
        margin-left: 22px;

        @media screen and (max-width: 945px) {
            display: none;
        }
    }
}

.profile-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--theme-color);
    margin-left: 22px;
}

.avatar-img-wrap {
    position: relative;
    cursor: pointer;
    margin-bottom: -5px;
}

.logout-ico {
    position: absolute;
    top: 3px;
}

.link-visible {
    display: none;

    @media only screen and (max-width:1100px) {
        display: block
    }
}