.slide-shape {
	border-radius: 14px;
	overflow: hidden;
}

.slider {

	
}

.slider-banner {
	background-color: var(--theme-button-color);
	border-radius: 14px;
	min-width: 200px;
	margin-right: 10px;
	display: flex;
	align-items: flex-end;
	padding: 10px 30px;
	cursor: pointer;
	height: 91px;
	margin-top: 4px;

	@media only screen and (max-width: 600px) {
		min-width: 150px;
	}

	@media only screen and (max-width: 350px) {
		display: none;
	}

	h3 {
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 1px;
		color: #fff;
		line-height: 1;
		text-transform: uppercase
	}
}

