.content-wrapper {

    &-header {
        position: relative;
        display: flex;
        align-items: left;
        width: 100%;
        min-height: 180px;
        justify-content: space-between;
        background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
            linear-gradient(to right top,
                #cf4af3,
                #e73bd7,
                #f631bc,
                #fd31a2,
                #ff3a8b,
                #ff4b78,
                #ff5e68,
                #ff705c,
                #ff8c51,
                #ffaa49,
                #ffc848,
                #ffe652);
        border-radius: 14px;
        padding: 20px 30px;
        margin-bottom: 20px;

        @media only screen and (max-width: 650px) {
            flex-direction: column;
        }

        @media screen and (max-width: 430px) {
            padding: 20px;
            min-height: 220px;
        }

        &-col1 {
            width: 50%;

            @media only screen and (max-width: 970px) {
                width: 85%;
            }

            @media only screen and (max-width: 450px) {
                width: 80%;
            }

            &-column {
                width: 80%;
            }
        }
    }

    &.overlay {
        pointer-events: none;
        transition: 0.3s;
        background-color: var(--overlay-bg);
    }

    &-img {
        position: absolute;
        bottom: 0px;
        right: 20px;
        max-height: 170px;

        @media only screen and (max-width: 650px) {
            max-height: 100px;
        }

        @media only screen and (max-width: 481px) {
            max-height: 90px;
        }
    }
}

.img-content {
    font-weight: 500;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin: 0;

    svg {
        width: 28px;
        margin-right: 14px;
    }
}

.content-text {
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.7em;
    color: #ebecec;
    display: -webkit-box;
    //-webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}

.content-wrapper-context {
    max-width: 350px;
}

.content-wrapper-img {
    height: 190px;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 400px) {
        height: 100px;
    }
}

.avatars-wrap {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    margin-bottom: 20px;
}

.gamebox-title {
    & p {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 10px;
        line-height: 1.2;
    }
}

.next-sign {
    background-color: var(--theme-button-color);
    animation: cursor-blink .5s infinite;
    animation-duration: 750ms;
}

/* .empty-space {} */

.eliminated-sign {
    color: rgba(255, 255, 255, .6);
}

@keyframes cursor-blink {
    0% {
        background: rgba(58, 109, 240, 1)
    }

    50% {
        background: rgba(58, 109, 240, 1)
    }

    100% {
        background: rgba(58, 109, 240, 0.0)
    }
}

.gamebox-lobby>div {
    border-radius: 20px !important;
    padding: 0px 20px !important;
    background-color: var(--hover-menu-bg);
    margin-bottom: 5px;
}

.gamebox-lobby>div>div:first-child {
    height: 40px !important;
}

.mobile-gamers-wrap {
    position: relative;
    background-color: var(--hover-menu-bg);
    max-width: 300px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.mobile-gamer {
    position: absolute;
    margin-left: 10px;
}

.driver {
    margin-bottom: 5px !important;
}

.driver-username {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}

.home-gamers-wrap {
    position: relative;
    height: 40px;
    margin-left: 10px;
}

.home-gamer {
    position: absolute;
}

.hgamers-img {
    margin: 0 !important;
}

.mob-gamer-correction {
    margin-top: 5px;
}

.seasons {
    min-height: 210px;

    @media only screen and (max-width: 1110px) {
        min-height: 250px;
    }

    @media only screen and (max-width: 1000px) {
        min-height: 270px;
    }

    @media only screen and (max-width: 650px) {
        min-height: 370px;
    }

    @media only screen and (max-width: 360px) {
        min-height: 400px;
    }

    @media only screen and (max-width: 342px) {
        min-height: 420px;
    }

    @media only screen and (max-width: 310px) {
        min-height: 430px;
    }

    &-column {
        min-height: 330px;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 1200px) {
            min-height: 460px;
        }
    }
}

.seasons-left {
    width: 60%;
    padding-right: 50px;

    @media only screen and (max-width: 650px) {
        width: 100%;
        padding-right: 0px;
    }

    &-column {
        width: 100%;
    }
}

.seasons-right {
    width: 40%;

    @media only screen and (max-width: 650px) {
        width: 100%;
    }

    &-column {
        width: 100%;
    }
}

.partyimg {
    margin-right: -20px !important;
}

.event-gal-item {
    border-radius: 14px;
}

.rounded {
    border-radius: 180%;
}

.goImg {
    @media only screen and (max-width: 500px) {
        position: absolute;
        right: 0px;
        width: 80px;
        object-fit: contain;
    }
}

.sidebar-img {
    max-height: 120px;

    @media only screen and (max-width: 1200px) {
        max-height: 80px;
    }

}

.in-chat-body {
    max-width: 600px;

  @media only screen and (max-width: 1450px) {
    max-width: 400px;
  }

  @media only screen and (max-width: 880px) {
    max-width: 320px;
  }

  @media only screen and (max-width: 700px) {
    max-width: 280px;
  }

  @media only screen and (max-width: 650px) {
    max-width: 100%;
  }
}

.img-in-chat-body {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    @media only screen and (max-width: 700px) {
        width: 80px;
        height: 80px;
      }
    
      @media only screen and (max-width: 650px) {
        width: 100px;
        height: 100px;
      }

      @media only screen and (max-width: 470px) {
        width: 80px;
        height: 80px;
      }
}