.left-side {
    overflow: auto;
    padding: 0 26px 0 26px;
    margin-bottom: auto;
}

.side-wrapper+.side-wrapper {
    margin-top: 20px;
   
}

.side-title {
    color: rgba(255, 255, 255, .6);
    ;
    margin-bottom: 14px;
}

.side-menu {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    svg {
        width: 16px;
        margin-right: 8px;
    }
}

.updates {
    position: relative;
    top: 0;
    right: 0;
    margin-left: auto;
    width: 18px;
    height: 18px;
    font-size: 11px;
}

.user-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0px 0px 10px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
        background-color: var(--hover-menu-bg);
        border-radius: 8px;
    }

    a {
        text-decoration: none;
        color: var(--theme-color);
        font-weight: 400;
        font-size: 14px;
        border-radius: 8px;
        transition: 0.3s;
        margin-left: 15px;
    }


}

.curr-user-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0px 0px 10px;
    border-radius: 8px;

    & a {
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        border-radius: 8px;
        transition: 0.3s;
        margin-left: 15px;
        cursor: auto;
    }
}

.avatar-img {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 180px;
    border: 2px solid white;
    z-index: 1;
    margin: 2px 0;
}

.username-wrap {
    border-radius: 0 20px 20px 0;
    margin-left: -5px;
    padding: 0 0;
    margin-top: -4px;

    & a {
        color: white;
    }

}

.avatar-wrap {
    position: relative;
}

.show-users {
    display: flex;
    flex-direction: column;
    
    flex-shrink: 0;

    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;

    max-height: 100%;
    background-color: var(--theme-bg-color);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
}

.close-sidebar {
    background-color: var(--theme-button-color);
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -10px;
    top: 10px;
    cursor: pointer;
    border-radius: 180px;
    font-size: 14px;

    p {
        font-size: 14px;
        margin-top: -5px;
        margin-left: -5px;
    }
}

.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--border-color);
    flex-shrink: 0;


    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.sidebar-btn {
    margin: 20px;
}

.sidebar-header {
    padding: 20px 26px 0 26px;
    
}